import { on } from 'delegated-events'

on('click', '.js-toggle-container .js-toggle-target', function (event) {
  const container = event.currentTarget.closest('.js-toggle-container')

  if (event.currentTarget.disabled) {
    event.preventDefault()
    return
  }

  if (event.currentTarget.classList.contains('js-toggle-chevron')) {
    event.currentTarget.classList.toggle('rotate-180')
  }

  if (container) {
    container.getElementsByClassName('js-toggle-content')[0].classList.toggle('hidden')
  }
})
