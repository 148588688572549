import { on } from 'delegated-events'

on('click', '.js-metadata-toggle', function (event) {
  this.querySelector('.js-chevron-down').classList.toggle('hidden')
  this.querySelector('.js-chevron-up').classList.toggle('hidden')

  const parentRow = this.closest('tr')
  parentRow.classList.toggle('no-border')

  const metadata = parentRow.nextElementSibling
  metadata.classList.toggle('hidden')
})
