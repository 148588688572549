import {on} from 'delegated-events';

on('auto-check-success', '.js-password-strength', function(event) {
  const state = this.closest("auto-check").querySelector(".js-password-strength-container")
  event.detail.response.text().then(function(data) {
    state.innerHTML = data
  })
})

on('change', '.js-password-strength', function(event) {
  const state = this.closest("auto-check").querySelector(".js-password-strength-container")
  if (this.value.length === 0) {
    state.innerHTML = null
  }
})
