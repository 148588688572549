class MultiFactorInputElement extends HTMLElement {
  constructor() {
    super()

    // Synchronize individual values with hidden input.
    const syncValue = () => {
      const hidden = this.querySelector('[data-multi-factor-input-field]')
      if (!hidden) return
      const values = Array.from(this.querySelectorAll('[data-multi-factor-index]')).map((input) => input.value)
      if (values.every((x) => !!x)) {
        hidden.value = values.join('')
      } else {
        hidden.value = ''
      }
    }

    this.addEventListener('input', (event) => {
      if (!event.target.hasAttribute('data-multi-factor-index')) return

      // Focus next input.
      if (event.target.value) {
        const index = Number(event.target.getAttribute('data-multi-factor-index'))
        this.querySelector(`[data-multi-factor-index='${index + 1}']`)?.focus()
      }

      syncValue()
    })

    this.addEventListener('keyup', (event) => {
      if (event.key !== 'Backspace') return
      if (!event.target.hasAttribute('data-multi-factor-index')) return
      if (event.target.value) return
      // Focus previous input.
      const index = Number(event.target.getAttribute('data-multi-factor-index'))
      const back = Math.max(0, index - 1)
      const input = this.querySelector(`[data-multi-factor-index='${back}']`)
      if (input) {
        input.value = ''
        input.focus()
      }
    })

    this.addEventListener('paste', (event) => {
      const text = event.clipboardData.getData('text')
      if (!text) return

      const inputs = Array.from(this.querySelectorAll('[data-multi-factor-index]'))
      if (inputs[0] != event.target) return

      const chars = text.split('')
      if (chars.length != inputs.length) return

      chars.forEach((c, i) => (inputs[i].value = c))
      inputs[inputs.length - 1].focus()
      syncValue()
    })
  }
}

window.customElements.define('multi-factor-input', MultiFactorInputElement)
