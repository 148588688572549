import {on} from 'delegated-events';

on('click', '.js-new-feature-close', function(event) {
  const dialog = document.querySelector('#feature-dialog')
  dialog.classList.add('hide');
  dialog.classList.remove('open')
})

on('click', '.js-new-feature-open', function(event) {
  const dialog = document.querySelector('#feature-dialog')
  dialog.classList.remove('hide')
  dialog.classList.add('open')
})

on('change', '.js-feature-name', function(event) {
  const featureType = document.querySelector('.js-feature-type');
  const typeSelect = featureType.querySelector('.js-feature-type-select');
  const featureLimit = document.querySelector('.js-feature-limit');
  const featureTurtle = document.querySelector('.js-feature-turtle-options');

  if (event.target.selectedOptions[0].classList.contains('js-feature-typed')) {
    featureType.classList.remove('hidden');
    featureLimit.classList.add('hidden');
    if (featureTurtle != null) {
      featureTurtle.classList.add('hidden');
    }

    for (let i=0; i < typeSelect.options.length; i++) {
      let opt = typeSelect.options[i];
      if (opt.classList.contains('js-feature-type-' + event.target.value)) {
        opt.classList.remove('hidden');
      } else if (opt.classList.contains('js-feature-type-option')) {
        opt.classList.add('hidden');
      }
    }
  } else if (event.target.selectedOptions[0].classList.contains('js-feature-turtle')) {
    const turtleSelects = featureTurtle.querySelectorAll('.js-feature-turtle-select');
    featureType.classList.add('hidden');
    featureLimit.classList.add('hidden');
    featureTurtle.classList.remove('hidden');

    for (let i=0; i < turtleSelects.length; i++) {
      let select = turtleSelects[i];
      if (select.classList.contains('js-feature-turtle-select-' + event.target.value)) {
	select.classList.remove('hidden');
      } else if (select.classList.contains('js-feature-turtle-select')) {
	select.classList.add('hidden');
      }
    }
  } else {
    featureType.classList.add('hidden');
    featureLimit.classList.remove('hidden');
    if (featureTurtle != null) {
      featureTurtle.classList.add('hidden');
    }
  }
})

on('change', '.js-feature-turtle-id-select', function(event) {
  const featureTurtle = document.querySelector('.js-feature-turtle-selects');
  const turtleSelects = featureTurtle.querySelectorAll('select');

  for (let i=0; i < turtleSelects.length; i++) {
    let select = turtleSelects[i];
    let options = select.options;
    for (let j=0; j < options.length; j++) {
      let opt = options[j];
      if (opt.classList.contains('js-feature-turtle-' + event.target.value)) {
        opt.classList.remove('hidden');
      } else {
        opt.classList.add('hidden');
      }
    }
  }

})
