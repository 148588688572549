import { on } from 'delegated-events';

on('change', '.js-vercel-form', function (event) {
  const selectedDatabase = this.querySelector(".js-vercel-databases").selectedOptions[0]
  const inputsContainer = this.querySelector(".js-vercel-new-db-inputs-container")
  const organizationInput = inputsContainer.querySelector(".js-vercel-new-db-organization")
  const databaseNameInput = inputsContainer.querySelector(".js-vercel-new-db-name")
  const selectedProjects = this.querySelectorAll(".js-vercel-project-checkbox:checked")
  const checkboxError = this.querySelector(".js-vercel-project-checkbox-error")

  if (selectedProjects.length > 0 && !checkboxError.classList.contains("hidden")) {
    checkboxError.classList.add("hidden")
  }

  if (selectedDatabase?.value == "new_database") {
    inputsContainer.classList.remove("hidden")
    organizationInput.setAttribute("required", true)
    databaseNameInput.setAttribute("required", true)
  } else {
    inputsContainer.classList.add("hidden")
    organizationInput.removeAttribute("required")
    databaseNameInput.removeAttribute("required")
  }
})

on('submit', '.js-vercel-form', function (event) {
  const submitButton = this.querySelector(".js-vercel-submit-button")
  const title = submitButton.querySelector(".js-vercel-button-title")
  const spinner = submitButton.querySelector(".js-vercel-button-spinner")
  const selectedProjects = this.querySelectorAll(".js-vercel-project-checkbox:checked")

  if (selectedProjects.length == 0) {
    const checkboxError = this.querySelector(".js-vercel-project-checkbox-error")
    checkboxError.classList.remove("hidden")
    event.preventDefault()
  } else {
    submitButton.setAttribute("disabled", true)
    title.classList.add("hidden")
    spinner.classList.remove("hidden")
  }
})
