import { on } from 'delegated-events';
on('change', '.js-existing-datasource-select', function(event) {
	const value = event.currentTarget.value
	const canTransition = value >=1
	var searchParams = new URLSearchParams(window.location.search)
	if (canTransition) {
		var searchParams = new URLSearchParams(window.location.search)
	    searchParams.set("template_id", value);
	    window.location.href = window.location.pathname + '?' + searchParams.toString();
	} else {
	    searchParams.delete("template_id");
	    window.location.href = window.location.pathname + '?' + searchParams.toString();
	}
})
