import { on } from 'delegated-events';

function handleChange(completer, event) {
  if (!completer.value || completer.value === "") return

  // wipe input value
  event.relatedTarget.value = ""

  const ul = event.target.querySelector(".js-feature-flag-add-actors")
  // check if actor has already been added
  const [value, displayValue] = completer.value.split(";")
  const existing = ul.querySelector(`[data-value="${value}"]`)
  if (existing) return

  // add actor
  const itemTemplate = document.querySelector("#feature-flag-actors-item-template")
  const cloneItem = itemTemplate.content.cloneNode(true)
  cloneItem.querySelector("li").setAttribute("data-value", value)
  cloneItem.querySelector(".js-item-text").textContent = !!displayValue ? displayValue : value
  ul.appendChild(cloneItem)

  // add hidden input
  const form = event.target.closest("details-dialog").querySelector("form")
  const inputTemplate = document.querySelector("#feature-flag-actors-input-template")
  const cloneInput = inputTemplate.content.cloneNode(true)
  var input = cloneInput.querySelector("input")
  input.value = value
  form.appendChild(cloneInput)
}

const completers = document.querySelectorAll('auto-complete.js-feature-flag-autocomplete')
completers.forEach((completer) =>
  completer.addEventListener('auto-complete-change', function(event) {
    handleChange(completer, event)
  })
)

on('click', '.js-new-feature-flag-actors-remove', function (event) {
  const li = event.target.parentElement
  const form = event.target.closest("details-dialog").querySelector("form")
  const value = li.dataset.value
  const hiddenInput = form.querySelector(`[value="${value}"]`)

  // remove div
  li.remove()
  // remove input
  hiddenInput.remove()
})

on('change', '.js-feature-flag-name', function(event) {
  const value = event.currentTarget.value
  const isVitessFlag = value.startsWith("vitess_flag_")
  const minimumVitessVersion = document.querySelector(".js-feature-flag-vitess-version")
  const allowedVitessVersions = document.querySelector(".js-feature-flag-allowed-vitess-versions")
  const disallowedVitessVersions = document.querySelector(".js-feature-flag-disallowed-vitess-versions")
  const vitessSettingsOpened = minimumVitessVersion.required
  const toggleButton = document.querySelector(".js-feature-flag-form .js-toggle-target")

  if (isVitessFlag) {
    if (!vitessSettingsOpened) {
      toggleButton.click()
    }
    toggleButton.disabled = true
    minimumVitessVersion.disabled = false
    allowedVitessVersions.disabled = false
    disallowedVitessVersions.disabled = false
    minimumVitessVersion.required = true
  } else {
    if (vitessSettingsOpened) {
      toggleButton.click()
    }
    minimumVitessVersion.required = false
    toggleButton.disabled = false
    minimumVitessVersion.disabled = true
    allowedVitessVersions.disabled = true
    disallowedVitessVersions.disabled = true
  }
})


document.addEventListener('details-dialog-close', function(event) {
  const dialog = event.target.closest("details-dialog")
  const autoComplete = dialog.querySelector('auto-complete')
  if (!autoComplete) return

  const ul = autoComplete.querySelector(".js-feature-flag-add-actors")
  if (!ul) return

  while (ul.firstElementChild) {
    ul.firstElementChild.remove()
  }
})
